<template>
  <div class="main-body">
    <div class="card-item">
      <div class="order-attention">
        <p>发布新版本</p>
      </div>
      <a-divider />
      <a-form
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="应用名称"> IOS APP </a-form-item>
        <a-form-item label="应用别名" extra="英文、唯一、用于应用分发">
          <a-input
            v-model:value="formState.code"
            placeholder="请输入应用别名"
          />
        </a-form-item>
        <a-form-item label="IPA上传">
          <UpFile v-model="formState.ipa" />
        </a-form-item>
        <a-form-item label="下载链接">
          <a-input
            v-model:value="formState.ios_url"
            placeholder="请输入ios应用市场下载链接"
          />
        </a-form-item>
        <a-form-item label="版本号" extra="客户端可见、示例：2.0.0">
          <a-input
            v-model:value="formState.vsersion_name"
            placeholder="请输入版本号"
          />
        </a-form-item>
        <a-form-item
          label="内部版本号"
          extra="客户端不可见、必须是纯数字、新版本必须大于上一个版本"
        >
          <a-input
            v-model:value="formState.version"
            placeholder="请输入内部版本号"
          />
        </a-form-item>
        <a-form-item label="应用描述">
          <a-textarea v-model:value="formState.description" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 6, offset: 2 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>历史版本</p>
      </div>
      <a-divider />
      <a-timeline>
        <a-timeline-item v-for="item in 6" :key="item">
          <span>版本号【10.0.{{ item }}】</span>
          <span class="action-time">2022-03-14 14:59:17</span>
        </a-timeline-item>
      </a-timeline>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import UpFile from "@/components/UpFile";

export default defineComponent({
  components: {
    UpFile,
  },
  setup() {
    const formState = reactive({
      code: "",
      ipa: "",
      ios_url: "",
      vsersion_name: "",
      vsersion: "",
      description: "",
    });

    const onSubmit = () => {};

    return {
      formState,
      onSubmit,
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 6,
      },
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;
  .action-body {
    display: flex;
    flex-wrap: wrap;
    .action-item {
      width: 20%;
      .action-item-btn {
        display: flex;
        background-color: #f0f2f5;
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        .action-icon {
          font-size: 36px;
          margin: 6px 10px;
          color: #52c41a;
        }
        .title {
          font-size: 14px;
        }
        .desc {
          color: #999999;
        }
      }
    }
  }
  .weixin-url {
    margin: 20px 0;
  }
  .action-time {
    margin-left: 20px;
  }
}
</style>